import React, { useEffect, useRef, useState } from 'react';
import Layout from '../components/layout';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  Row,
  Col,
  Card,
} from 'reactstrap';
import { navigate } from 'gatsby';
import PeopleLogo from '../assets/images/complete-services.png';
import StarIcon from '../assets/images/starIconDark.svg';
import InfoIcon from '../assets/images/info-icon.svg';
import Background from '../assets/images/bg-pattern.png';
import TikTok from '../assets/images/tiktok-circle.svg';
import TikTokHover from '../assets/images/tiktok-circle-white.svg';
import Facebook from '../assets/images/facebook-circle.svg';
import FacebookHover from '../assets/images/facebook-circle-white.svg';
import Instagram from '../assets/images/instagram-circle.svg';
import InstagramHover from '../assets/images/instagram-circle-white.svg';

const CompleteServiceForm = ({ location }) => {
  const formInstance = useRef();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const id = params.get('id');

  const [data, setData] = useState();
  const [serviceName, setServiceName] = useState('');

  const invalidMessage = (e) => {
    const value = e?.target?.value;
    e?.target?.setCustomValidity('');
    if (e?.target?.validity?.patternMismatch || !value) {
      e?.target?.setCustomValidity('La url que ingresaste no es valida.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(formInstance.current);
    const object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    await fetch(
      `${process.env.GATSBY_API_URL}/v1/invoices/complete-invoice-data`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(object),
      },
    )
      .then(() => {
        navigate('/solicitud-enviada');
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (!id || !token) {
      navigate('/');
    }
  }, [id, token]);

  useEffect(() => {
    if (id && !data) {
      (async () => {
        const response = await fetch(
          `${process.env.GATSBY_API_URL}/v1/invoices/${id}/external`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          },
        ).catch(console.log);
        const res = await response.json();
        if (res?.statusCode >= 400 && res?.statusCode <= 599) {
          navigate(`/error-servicio?error=${res?.statusCode}`);
        }
        setData(res);
        setServiceName(res?.talentService?.service?.name);
      })();
    }
  }, [id, token, data]);

  return (
    <Layout>
      <section className="complete-service__main position-relative">
        <div className="complete-service__main-bg   d-none d-md-block">
          <img
            className="img-fluid"
            src={Background}
            alt="background-servicio"
          />
        </div>
        <Container>
          <Row className="mt-5">
            <Col
              xs={12}
              md={6}
              className="d-flex flex-column justify-content-center"
            >
              <h2 className="complete-service__main-title  d-inline">
                ESTÁS A NADA
              </h2>
              <h2 className="complete-service__main-subtitle d-inline">
                DE RECIBIR UN{' '}
                {serviceName
                  ? serviceName?.toLowerCase() === 'seguir'
                    ? 'FOLLOW'
                    : serviceName?.toUpperCase()
                  : 'SERVICIO'}
              </h2>
              <h3 className="complete-service__main-small">
                de tu celebridad favorita
              </h3>

              <p className="complete-service__main-description">
                Sigue los pasos a continuación para completar tu solicitud y
                prepárate para mostrarle a todos que estás en contacto con tu
                BFF.
              </p>
            </Col>
            <Col xs={12} md={6}>
              <img
                className="img-fluid d-none d-md-block"
                src={PeopleLogo}
                alt="people-servicio"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="complete-service__second">
        <Container className="px-0 px-md-2">
          <Card className="complete-service__card">
            <Row className="mx-0">
              <Col xs={12} md={{ offset: 1, size: 10 }} className=" px-4 py-5 ">
                <form ref={formInstance} onSubmit={handleSubmit}>
                  <h2 className="mb-3 mb-md-5 complete-service__small-title d-flex align-items-center">
                    <div>
                      Estás<span> ready?</span>
                    </div>
                    <img className="img-fluid" src={StarIcon} alt="star-icon" />
                  </h2>
                  <FormGroup>
                    <Label className="w-100 Form__label">
                      <span className="mb-2 d-block">
                        Así personalizaste tu mensaje previamente, si deseas
                        ajustar algo puedes hacer click aquí
                      </span>
                      <Input
                        key={data?.serviceIndications}
                        className="Form__input"
                        type="textarea"
                        maxLength={500}
                        name="serviceIndications"
                        placeholder="Indicaciones"
                        onChange={invalidMessage}
                        defaultValue={data?.serviceIndications}
                      />
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label className="w-100 Form__label">
                      <span className="mb-2 d-block">
                        {serviceName?.toLowerCase() === 'seguir'
                          ? 'Déjanos el link del perfil donde quieres que te sigan'
                          : serviceName?.toLowerCase() === 'me gusta'
                          ? 'Déjanos el link de la publicación donde quieres un Me Gusta de tu BFF'
                          : serviceName?.toLowerCase() === 'comentario'
                          ? 'Déjanos el link de la publicación donde quieres recibir el Comentario'
                          : 'Déjanos el link donde quieres recibir el Servicio'}
                      </span>
                      <Input
                        className="Form__input"
                        type="text"
                        name="serviceUrl"
                        placeholder="Link"
                        onChange={invalidMessage}
                        pattern="(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)"
                      />
                    </Label>
                  </FormGroup>
                  <article className="complete-service__warning p-3">
                    <p className="mb-3 complete-service__warning-title">
                      <img
                        className="img-fluid"
                        src={InfoIcon}
                        alt="info-icon"
                      />{' '}
                      Importante
                    </p>
                    <p className="complete-service__warning-text">
                      <img
                        className="img-fluid"
                        src={StarIcon}
                        alt="star-icon"
                      />{' '}
                      Recuerda que tu perfil debe estar público.
                    </p>
                    <p className="complete-service__warning-text">
                      <img
                        className="img-fluid"
                        src={StarIcon}
                        alt="star-icon"
                      />{' '}
                      Verifica muy bien el link de tu publicación, ya que no lo
                      podrás cambiar más adelante.
                    </p>
                  </article>
                  <FormGroup className="mt-5">
                    <Button className="Form__button--primary btn btn-#A515DB">
                      CONFIRMAR
                    </Button>
                  </FormGroup>
                </form>
              </Col>
            </Row>
            <Row className="mx-0 pb-5  py-md-5 complete-service__help">
              <Col xs={12} md={{ offset: 1, size: 10 }} className="mb-4">
                <h3 className="complete-service__small-title --dark">
                  <div>
                    ¿NECESITAS AYUDA PARA <span> OBTENER EL LINK?</span>
                  </div>
                </h3>
                <p className="complete-service__text">
                  No te preocupes, selecciona la red social de la que quieres
                  obtenerlo y te lo explicamos en un corto video.
                </p>
              </Col>

              <Col xs={4} md={{ offset: 1, size: 2 }}>
                <button className="complete-service__box facebook">
                  <img
                    className="complete-service__box-image"
                    src={Facebook}
                    alt="facebook-icon"
                  />{' '}
                  <img
                    className="complete-service__box-image--hover"
                    src={FacebookHover}
                    alt="facebook-icon-hover"
                  />
                  <span className="complete-service__box-title">Facebook</span>
                </button>
              </Col>
              <Col xs={4} md={2}>
                <button className="complete-service__box instagram">
                  <img
                    className="complete-service__box-image"
                    src={Instagram}
                    alt="instagram-icon"
                  />
                  <img
                    className="complete-service__box-image--hover"
                    src={InstagramHover}
                    alt="instagram-icon-hover"
                  />
                  <span className="complete-service__box-title">Instagram</span>
                </button>
              </Col>
              <Col xs={4} md={2}>
                <button className="complete-service__box tiktok">
                  <img
                    className="complete-service__box-image"
                    src={TikTok}
                    alt="tiktok-icon"
                  />
                  <img
                    className="complete-service__box-image--hover"
                    src={TikTokHover}
                    alt="tiktok-icon-hover"
                  />
                  <span className="complete-service__box-title">TikTok</span>
                </button>
              </Col>
            </Row>
          </Card>
        </Container>
      </section>
    </Layout>
  );
};

export default CompleteServiceForm;
